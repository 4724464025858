import { ReactNode } from 'react';
import { Col } from 'antd';
import { Wrapper } from './Title.style';
type Props = {
  text: ReactNode;
  subText?: ReactNode;
  extra?: ReactNode;
  $mobileFontSize?: number;
};
const Title = ({
  text,
  subText,
  extra,
  $mobileFontSize
}: Props) => {
  return <Wrapper $mobileFontSize={$mobileFontSize}>
            <Col>
                <div className="text">{text}</div>
                <div className="sub-text">{subText}</div>
            </Col>
            <div className="extra">{extra}</div>
        </Wrapper>;
};
export default Title;