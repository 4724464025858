import { IGetRecommendTipsTricksUsecase } from '@modules/tipsAndTricks/domain/ports/getRecommendTipsTricks.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { shuffleArrayWithLimitOutput } from '@shared/utils/random.utils';
import { IRecommendTipsTricksRepository } from '../ports/recommendTipsTricks.repository.port';
export class GetRecommendTipsTricksUsecase implements IGetRecommendTipsTricksUsecase {
  constructor(private readonly recommendTipsTricksRepository: IRecommendTipsTricksRepository) {}
  async handle(): IGetRecommendTipsTricksUsecase.getRecommedTipsTricksOutput {
    const result = await this.recommendTipsTricksRepository.getRecommendTipsTricks();
    const value = result.value;
    if (result.isLeft()) return left(value);
    const shuffledResult = shuffleArrayWithLimitOutput(value, 3);
    return right(shuffledResult);
  }
}