import { GetRecommendTipsTricksDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { IRecommendTipsTricksRepository } from '@modules/tipsAndTricks/application/ports/recommendTipsTricks.repository.port';
import { left, right } from '@shared/utils/either.utils';
import { RecommendTipsTricksEntityMapper } from '../../outbound/ports/recommendTipsTricks.mapper';
export class RecommendTipsTricksRepositoryImpl implements IRecommendTipsTricksRepository {
  async getRecommendTipsTricks(): IRecommendTipsTricksRepository.getRecommendTipsTricksOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetRecommendTipsTricksDocument
      });
      const output = data.getRecommendTipsTricks.map(tip => RecommendTipsTricksEntityMapper.toDomain(tip));
      return right(output);
    } catch (error: any) {
      console.error('err RecommendTipsTricksRepositoryImpl.getRecommendTipsTricks', error);
      return left(error);
    }
  }
}