import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "SeeAllstyle__Wrapper",
  componentId: "sc-153lp7-0"
})(["div{", "}svg{fill:", ";transition:fill 0.3s;}&:hover{a,svg{color:", ";fill:", ";}}@media (max-width:", "){&:hover{a,svg{color:", ";fill:", ";}}}"], {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "gap": "0.25rem"
}, ({
  $fill
}) => $fill, ({
  $hoverColor
}) => $hoverColor, ({
  $hoverColor
}) => $hoverColor, ({
  theme
}) => theme.breakpoint.md, ({
  $fill
}) => $fill, ({
  $fill
}) => $fill);